import React, { useState, useEffect } from 'react';
import '../ebook.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import axios from 'axios';
import DownloadAllModal from './download-all-modal';

function CutAnalysis({certificateNumber, setCertificateNumber, productData, getProductData}){
 // Added - start
 const [show, setShow] =useState(false);

 const handleShow =()=> setShow(true);
 const handleClose = () => setShow(false);

 //end

  if(Object.keys(productData).length > 0)
  {
    var stageCounter = 0;
    var mediaCounter = 0;
    return(
      <Row className="cutAnalysisRow">
      <Col md={12}>
      <Tab.Container id="left-tabs-example" defaultActiveKey="caTab1">
      <Row>
      <Col md={5}>
      <Nav variant="pills" className="flex-column caTabSection">
      {productData.cutAnalysisData && productData.cutAnalysisData.map((cutAnalysisSingularData) => {
        stageCounter = stageCounter + 1;
        var eventKeyCounter = "caTab" + stageCounter;
        return (
          <Nav.Item className="caTabs" key={eventKeyCounter}>
          <Nav.Link eventKey={eventKeyCounter} className="caTabLink">
          <h3>{cutAnalysisSingularData.cutAnalysisContentHeading}</h3>
          <p>{cutAnalysisSingularData.cutAnalysisContentSubtitle}</p>
          </Nav.Link>
          </Nav.Item>
          );
      })}
      </Nav>
      </Col>
      <Col md={7}>
      <Tab.Content>
      {productData.cutAnalysisData && productData.cutAnalysisData.map((cutAnalysisSingularData) => {
        mediaCounter = mediaCounter + 1;
        var eventKeyCounter = "caTab" + mediaCounter;
        return (
          cutAnalysisSingularData.cutAnalysisContentExtension === null ? (<Tab.Pane eventKey={eventKeyCounter} key={eventKeyCounter}>
            <Row className="caTabContent" >
            <Col md={12} className="cutAnalysisVideo">
            <Image src={cutAnalysisSingularData.cutAnalysisContentMediaUrl} className="caTabImage"/>
            </Col>
            <Col md={8} className="caTabCertificateNumber">
            <p>{cutAnalysisSingularData.cutAnalysisContentSubtitle}</p>
            <p>{certificateNumber}</p>
            </Col>
            <Col md={4} className="caTabDownloadCTA">
            <Button href="#" onClick={handleShow}>DOWNLOAD ALL</Button>
            </Col>
            <p>{cutAnalysisSingularData.cutAnalysisContentDescription}</p>
            </Row>
            </Tab.Pane>)
          : cutAnalysisSingularData.cutAnalysisContentExtension === "mp4" ? (<Tab.Pane eventKey={eventKeyCounter}>
            <Row className="caTabContent">
            <Col md={12} className="cutAnalysisVideo">
            <video src={cutAnalysisSingularData.cutAnalysisContentMediaUrl} width="100%" type="video/mp4" controls autoPlay loop className="cutAnalysisVideo"/>
            </Col>
            <Col md={8} className="caTabCertificateNumber">
            <p>{cutAnalysisSingularData.cutAnalysisContentSubtitle}</p>
            <p>{certificateNumber}</p>
            </Col>
            <Col md={4} className="caTabDownloadCTA">
            <Button href="#" onClick={handleShow}>DOWNLOAD ALL</Button>
            </Col>
            <p>{cutAnalysisSingularData.cutAnalysisContentDescription}</p>
            </Row>
            </Tab.Pane>) 
          : cutAnalysisSingularData.cutAnalysisContentExtension === "html" ? (
            <Tab.Pane eventKey={eventKeyCounter}>
            <Row className="caTabContent">
            <Col md={12} className="cutAnalysisVideo">
            <iframe src={cutAnalysisSingularData.cutAnalysisContentMediaUrl} className="caTabImage"/>
            </Col>
            <Col md={8} className="caTabCertificateNumber">
            <p>{cutAnalysisSingularData.cutAnalysisContentSubtitle}</p>
            <p>{certificateNumber}</p>
            </Col>
            <Col md={4} className="caTabDownloadCTA">
            {/* Updated - start */}
          <Button href="#" onClick={handleShow}>DOWNLOAD ALL</Button>
                  {/* end */}
            </Col>
            <p>{cutAnalysisSingularData.cutAnalysisContentDescription}</p>
            </Row>
            </Tab.Pane>)
          : (<Tab.Pane eventKey={eventKeyCounter}>
            <Row className="caTabContent">
            <Col md={12} className="cutAnalysisVideo">
            <Image src={cutAnalysisSingularData.cutAnalysisContentMediaUrl} className="caTabImage"/>
            </Col>
            <Col md={8} className="caTabCertificateNumber">
            <p>{cutAnalysisSingularData.cutAnalysisContentSubtitle}</p>
            <p>{certificateNumber}</p>
            </Col>
            <Col md={4} className="caTabDownloadCTA">
            {/* Updated - start */}
          <Button href="#" onClick={handleShow}>DOWNLOAD ALL</Button>
                  {/* end */}
            </Col>
            <p>{cutAnalysisSingularData.cutAnalysisContentDescription}</p>
            </Row>
            </Tab.Pane>)
          );
      })}
      </Tab.Content>
      {/* Modal will be shown according to button click */}
      <DownloadAllModal show={show} handleClose={handleClose} />
      </Col>
      </Row>
      </Tab.Container>
      </Col>
      </Row>
      );
  }
}

export default CutAnalysis;
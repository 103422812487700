import { React, useState } from "react";
import "../ebook.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import parse from "html-react-parser";

function DownloadAllModal({ show, handleClose, certificateNumber }) {
  const [showAlert, setShowAlert] = useState(false);
  const [bookedCode, setBookedCode] = useState("");
  const [alertMessage, setAlertMessage] = useState(null);

  const validateCodeAndDownloadContent = async () => {
    const apiURL =
      "https://staging.jannpaul.com/wp-content/plugins/les-product-management/public/factory-jannpaul-sync-handler.php?reactContentHandler=getEbookDataForReact";
    const responseContentData = await axios.get(
      apiURL +
      "&certificateNumber=" +
      certificateNumber +
      "&bookedCode=" +
      bookedCode
      );
      setAlertMessage(responseContentData.data);
  };

  const displayEnteredBookedCode = (e) => {
    e.preventDefault();
    setBookedCode(e.target.value);
  };

  const showAlertBox = () => setShowAlert(true);
  const hideAlertBox = () => setShowAlert(false);
  return (
    <Modal show={show} centered>
      <Modal.Header>
        <h5 className="modal-title" id="exampleModalLabel">
          Download Media
        </h5>
        {/* to be replaced with Button if necessary*/}
        <button
          type="button"
          className="close close-modal"
          onClick={() => {
            handleClose(); 
            hideAlertBox();
            setBookedCode("");
          }}
        >
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {alertMessage && showAlert === true ?  (
          <Alert key={alertMessage.status} variant={alertMessage.status}>
            {parse(alertMessage.message)}
          </Alert>
        ) : (
          <p></p>
        )}
        <Form.Control
          type="text"
          placeholder="Enter booked no here"
          name="bookedCode"
          // autoFocus="autoFocus"
          onChange={displayEnteredBookedCode}
          value={bookedCode}
        />
      </Modal.Body>
      {/* CSS class applied here is used for Button */}
      <Modal.Footer>
        <Button onClick={()=>{validateCodeAndDownloadContent(); showAlertBox()}} variant="primary">
          DOWNLOAD
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DownloadAllModal;

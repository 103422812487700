import React from 'react';
import '../ebook.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

function ethicalSourcing(){
	return(
		<Row>
		<Row>
		<Col className="earthMinedDiamondsTitle">
		<h3 className="ebookMobileTitle">ETHICAL SOURCING</h3>
		<h4 className="ebookMobileSubTitle">RESPONSIBLE DIAMOND SOURCING</h4>
		</Col>
		</Row>
		<Row>
		<Col>
		<p>Our Master Diamond Cutter is an active member of the Responsible Jewelry Council (RJC) and Kimberley Process (KP) compliant. Even though our 
		cutting facility is registered with these boards, the only way to truly track ethical sourcing is by having complete control over the sourcing and 
		diamond cutting process.</p>
		<p>This is achieved by having only 1 diamond cutter with the same strong belief of ethical sourcing. In 2018, we searched for diamond cutters around 
		the world, who are capable of cutting the White Series collection and compliant with ethical sourcing. It took us 7 years to find the highest precision 
		cutter with the same strong belief and practices. Dealing with multiple cutters would not allow us to effectively track down the true origin of the 
		diamond source as it can be passed from hand to hand, which is a common practice in the diamond industry, regardless of which diamond board 
		you are on.</p>
		<p>All of our diamonds are in-house or custom cut made to order only. We do not provide drop shipment or JIT (Just-In-Time) inventory like most 
		vendors do. Drop shipment goods are loosely traded with limited regulation and are often passed on hand to hand, impossible to track. Online 
		vendors that usually sell these stocks tend to censor the certificate number of the diamond on their website, to prevent consumers from tracing 
		or verifying the origin of the diamond. As a result, false claims can be easily made.</p>
		<p>We are actively and progressively involved in the rough sourcing and cutting process to ensure the best quality of diamonds and social practices. 
		In JannPaul, we keep track of every single diamond sourced through the help of our proactive Diamond Master Cutter</p>
		</Col>
		</Row>
		<Row>
		<Col md={12} className="ethicalSourcingImage">
		<Image src="https://staging.jannpaul.com/wp-content/uploads/2022/12/Responsible-jwellery-counsil.jpg"  className="ethicalSourcingImage"/>
		</Col>
		</Row>
		<Row>
		<Col className="marginBottomZero">
		<p>- No terrorism financing</p>
		<p>- Full protection and support of human rights</p>
		<p>- Minimize environmental impact</p>
		<p>- Practicing safe and responsible labour operations</p>
		<p>- Continuous support & development of carbon free emission goals</p>
		<p>- Ensuring support of mining community welfare </p>
		<p>- No involvement of any 3rd party diamond illegal trade practices</p>
		</Col>
		</Row>
		</Row>
		);
}

export default ethicalSourcing;
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import DownloadAllModal from "../ebook-right-template/download-all-modal";
const EbookMobileCutAnalysis = ({ productData, certificateNumber }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      {/* Menu Title */}
      <Row className="ebookSectionTitle">
        <h4>Cut Analysis</h4>
      </Row>
      {/* cut analysis details */}
      <Row>
        <Accordion>
          {productData.cutAnalysisData &&
            productData.cutAnalysisData.map(
              (cutAnalysisSingularData, index) => (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>
                    <h4>{cutAnalysisSingularData.cutAnalysisContentHeading}</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      {cutAnalysisSingularData.cutAnalysisContentExtension ===
                        null ||
                      cutAnalysisSingularData.cutAnalysisContentExtension ===
                        "png" ||
                      cutAnalysisSingularData.cutAnalysisContentExtension ===
                        "jpg" ||
                      cutAnalysisSingularData.cutAnalysisContentExtension ===
                        "jpeg" ? (
                        <Image
                          src={
                            cutAnalysisSingularData.cutAnalysisContentMediaUrl
                          }
                        />
                      ) : cutAnalysisSingularData.cutAnalysisContentExtension ===
                        "mp4" ? (
                        <video
                          src={
                            cutAnalysisSingularData.cutAnalysisContentMediaUrl
                          }
                          width="100%"
                          type="video/mp4"
                          controls
                          autoPlay
                          loop
                        />
                      ) : (
                        <h4>iframe to be displayed</h4>
                      )}
                    </Row>
                    <Row>
                      <Col xs={7}>
                        <p>
                          {cutAnalysisSingularData.cutAnalysisContentHeading}
                          <br />
                          Certificate No. {certificateNumber}
                        </p>
                      </Col>
                      <Col xs={5} className="alignRight">
                        <Button className="ebookDownloadAllButton" onClick={handleShow}>DOWNLOAD ALL</Button>
                      </Col>
                    </Row>
                    <Row className="ebookProductDescription">
                      <p>
                        {cutAnalysisSingularData.cutAnalysisContentDescription}
                      </p>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              )
            )}
          {/* Modal will be shown according to button click */}
          <DownloadAllModal
            show={show}
            handleClose={handleClose}
            certificateNumber={certificateNumber}
          />
        </Accordion>
      </Row>
    </>
  );
};
export default EbookMobileCutAnalysis;

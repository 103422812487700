import {useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import DownloadAllModal from '../ebook-right-template/download-all-modal';
const EbookMobileYourDiamondJourney = ({productData, certificateNumber}) =>{
    const [show, setShow] = useState(false);
    const handleShow =() => setShow(true);
    const handleClose = () => setShow(false);
    return (<>
    {/* Menu Title */}
      <Row className="ebookSectionTitle">
      <h4>Your Diamond Journey</h4>
      </Row>
      {/* diamond journey details */}
      <Row>
      <Accordion>
        {productData.diamondJourneyData && productData.diamondJourneyData.map((diamondJourneySingularData, index) => (
            <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header><h4>{diamondJourneySingularData.diamondJourneyContentHeading}</h4></Accordion.Header>
            <Accordion.Body>
             <Row>
              <Image src={diamondJourneySingularData.diamondJourneyContentMediaUrl}/>
             </Row>
             <Row>
              <Col xs={7}>
                 <p>{diamondJourneySingularData.diamondJourneyContentHeading}<br />Certificate No. {certificateNumber}</p>
              </Col>
              <Col xs={5} className="alignRight">
              <Button className="ebookDownloadAllButton" onClick={handleShow}>DOWNLOAD ALL</Button>
              </Col>
             </Row>
             <Row className="ebookProductDescription">
              <p>{diamondJourneySingularData.diamondJourneyContentDescription}</p>
             </Row>
            </Accordion.Body>
          </Accordion.Item>
        ))}
        {/* Modal will be shown according to button click */}
       <DownloadAllModal show={show} handleClose={handleClose} certificateNumber={certificateNumber}/>
      </Accordion>
      </Row>
      </>
    );
}
export default EbookMobileYourDiamondJourney;
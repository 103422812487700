import React, { useState, useEffect, useRef } from "react";
import "../ebook.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Ratio from "react-bootstrap/Ratio";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Carousel from "react-bootstrap/Carousel";
import Table from "react-bootstrap/Table";
import { Figure, Alert, Container } from "react-bootstrap";
import parse from "html-react-parser";


function LabReport({
  certificateNumber,
  setCertificateNumber,
  productData,
  getProductData,
}) {
  const [activeThumbnail, setActiveThumbnail] = useState(0);
  // const [iframeHeight, setIframeHeight] =useState("0px");
  // const iframeRef =useRef(0);

  var productThumbnails = productData.labReportData;

  // console.log("product thumbnails: " + JSON.stringify(productThumbnails));

  //previous button
  function prev() {
    if (activeThumbnail > 0) {
      setActiveThumbnail(activeThumbnail - 1); //decrementing activeThumbnail
    }
  }

  //next button
  function next() {
    if (activeThumbnail < productThumbnails.length - 1) {
      setActiveThumbnail(activeThumbnail + 1); //incrementing activeThumbnail
    }
  }

  // Thumbnail Table
  const thumbnailTable = () => {
    const thumbnailTableRow = []; //storing each row inside tbody

    // adding table rows
    for (let i = 1; i < productThumbnails.length; i += 2) {
      thumbnailTableRow.push(
        <tr key={i}>
          {/* Each row containes 2 thumbnails */}
          <td
            onClick={() => {
              setActiveThumbnail(productThumbnails[i].id);
            }}
          >
            {/* setting active thumbnail */}
            <Image
              src={productThumbnails[i].image}
              className={`${
                productThumbnails[i].id === activeThumbnail
                  ? "lrActiveThumbnail"
                  : ""
              } lrThumbnailTableImage`}
              onClick={() => setActiveThumbnail(productThumbnails[i])}
            />
            <p>{productThumbnails[i].name}</p>
          </td>
          <td onClick={() => setActiveThumbnail(productThumbnails[i + 1].id)}>
            {/* setting active thumbnail */}
            <Image
              src={productThumbnails[i + 1].image}
              className={`${
                productThumbnails[i + 1].id === activeThumbnail
                  ? "lrActiveThumbnail"
                  : ""
              } lrThumbnailTableImage`}
              onClick={() => setActiveThumbnail(productThumbnails[i + 1])}
            />
            <p>{productThumbnails[i + 1].name}</p>
          </td>
        </tr>
      );
    }
    return thumbnailTableRow; //Returning all the rows
  };

  // If preview is not available

  const noPreview = () => {
    return (
      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <Alert variant="warning" className="lrAlertWarning">
            No Preview Available
          </Alert>
        </Col>
        <Col md={3}></Col>
      </Row>
    );
  };

  // Comparison Analysis
  const comparisonAnalysis = () => {
    const currentDisplay =
      productThumbnails[activeThumbnail].comparisonAnalysis;
    console.log(
      "extension: " +
        productThumbnails[activeThumbnail].extension +
        " || name: " +
        productThumbnails[activeThumbnail].name
    );
    return (
      <Col md={12}>
        <Col md={12} className="lrComparisonAnalysisImgandVideo">
          {productThumbnails[activeThumbnail].extension != "html" ? (
            //Images
            <>
              <Col md={3}>
                <Image
                  src={currentDisplay.img1}
                  className="lrComparisonAnalysisImg"
                />
              </Col>
              <Col md={3}>
                <Image
                  src={currentDisplay.img2}
                  className="lrComparisonAnalysisImg"
                />
              </Col>
              <Col md={3}>
                <Image
                  src={currentDisplay.img3}
                  className="lrComparisonAnalysisImg"
                />
              </Col>
              <Col md={3}>
                <Image
                  src={currentDisplay.img4}
                  className="lrComparisonAnalysisImg"
                />
              </Col>
            </>
          ) : (
            //videos
            <>
              <Col md={3}>
                <video
                  className="lrComparisonAnalysisVideo"
                  muted=""
                  autoplay=""
                  loop=""
                >
                  <source src={currentDisplay.video1} type="video/mp4" />
                </video>
              </Col>
              <Col md={3}>
                <video
                  className="lrComparisonAnalysisVideo"
                  muted=""
                  autoplay=""
                  loop=""
                >
                  <source src={currentDisplay.video2} type="video/mp4" />
                </video>
              </Col>
              <Col md={3}>
                <video
                  className="lrComparisonAnalysisVideo"
                  muted=""
                  autoplay=""
                  loop=""
                >
                  <source src={currentDisplay.video3} type="video/mp4" />
                </video>
              </Col>
              <Col md={3}>
                <video
                  className="lrComparisonAnalysisVideo"
                  muted=""
                  autoplay=""
                  loop=""
                >
                  <source src={currentDisplay.video4} type="video/mp4" />
                </video>
              </Col>
            </>
          )}
        </Col>
        <Row>
          {/* Parsing string to JSX */}
          {parse(currentDisplay.writeup)}
        </Row>
      </Col>
    );
  };

  // console.log("product thumbnail: " + JSON.stringify(productThumbnails));
  // const onLoad=()=>{
  //   // setHeight(ref.current.contentWindow.document.body.scrollHeight + 'px');
  //   setIframeHeight("200px");
  // }
  // console.log(productData.labReportCertificate);
  return (productThumbnails &&
    <Container>
      {/*<Row>*/}
      <Col md={12} className="pdfEmbeder">
        <embed className="eBookCertificate" src={productData.labReportCertificate}></embed>
      </Col>
        {/* Thumnail Slider */}
      <Row className="lrSliderRow">
      {/*Slider Start*/}
      <Col md={1}></Col>
      <Col md={6}>
      <Col className="iframeRelative">
       {productThumbnails.map((thumbnail, index) => (
            <Figure
              key={index}
              onClick={() => setActiveThumbnail(thumbnail.id)}
              style={{
                display: thumbnail.id === activeThumbnail ? "block" : "none",
              }}
              className="lrThumbnailSliderFigure"
            >
              {thumbnail.iframe === null ? (
                <Image src={thumbnail.image} className="lrSliderImage" />
              ) : (
                // <Ratio key="ratio">
                  <iframe
                  // ref={iframeRef}
                  // onLoad={onLoad}
                  className={`${thumbnail.name === "360 Degree Video" ? "threeSixtyDegreeVideo" : "fireVideo" } productThumbnails`}
                  src={thumbnail.iframe}
                ></iframe>
                //  </Ratio>
              )}
              <p>{certificateNumber}</p>
            </Figure>
          ))}
       </Col>
       <Image
            onClick={prev}
            src="https://staging.jannpaul.com/wp-content/uploads/2024/04/arrow_left.png"
            id="lrPrevBtn"
            style={{ cursor: activeThumbnail === 0 ? "no-drop" : "pointer" }}
          />
          <Image
            onClick={next}
            src="https://staging.jannpaul.com/wp-content/uploads/2024/04/arrow_right.png"
            id="lrNextBtn"
            style={{
              cursor:
                activeThumbnail === productThumbnails.length - 1
                  ? "no-drop"
                  : "pointer",
            }}
          />
      </Col>
      <Col md={4}>
      <Table>
            <tbody className="lrThumbnailTableBody">
              {/* adding 360 deg first and then rest of the table rows */}
              <tr key={productThumbnails[0].id}>
                <td colSpan={2}>
                  {/* setting active thumbnail */}
                  <Image
                    src={productThumbnails[0].image}
                    className={`${
                      productThumbnails[0].id === activeThumbnail
                        ? "lrActiveThumbnail"
                        : ""
                    } three-sixty-image`}
                    onClick={() => setActiveThumbnail(productThumbnails[0].id)}
                  />
                </td>
              </tr>
              {thumbnailTable()}
            </tbody>
          </Table>
      </Col>
      <Col md={1}></Col>
      </Row>


{/*
           <Col md={1} className="arrowColumn">
          <Image
            onClick={prev}
            src="https://staging.jannpaul.com/wp-content/uploads/2024/04/arrow_left.png"
            id="lrPrevBtn"
            style={{ cursor: activeThumbnail === 0 ? "no-drop" : "pointer" }}
          />
        </Col>
        <Col md={4} className="sliderColumn">
          {productThumbnails.map((thumbnail, index) => (
            <Figure
              key={index}
              onClick={() => setActiveThumbnail(thumbnail.id)}
              style={{
                display: thumbnail.id === activeThumbnail ? "block" : "none",
              }}
              className="lrThumbnailSliderFigure"
            >
              {thumbnail.iframe === null ? (
                <Image src={thumbnail.image} className="lrSliderImage" />
              ) : (
                // <Ratio key="ratio">
                  <iframe
                  // ref={iframeRef}
                  // onLoad={onLoad}
                  className="lrSliderIframe"
                  src={thumbnail.iframe}
                ></iframe>
                //  </Ratio>
              )}
              <p>{certificateNumber}</p>
            </Figure>
          ))}
        </Col>
        <Col md={1} className="arrowColumn">
          <Image
            onClick={next}
            src="https://staging.jannpaul.com/wp-content/uploads/2024/04/arrow_right.png"
            id="lrNextBtn"
            style={{
              cursor:
                activeThumbnail === productThumbnails.length - 1
                  ? "no-drop"
                  : "pointer",
            }}
          />
        </Col>
        {/*Slider end*/}
{/*        <Col md={1}></Col>
        {/* Thumbnail Table */}
{/*         <Col md={4}>
          <Table>
            <tbody className="lrThumbnailTableBody">
              {/* adding 360 deg first and then rest of the table rows */}
{/*               <tr key={productThumbnails[0].id}>
                <td colSpan={2}>
                  {/* setting active thumbnail */}
 {/*                  <Image
                    src={productThumbnails[0].image}
                    className={`${
                      productThumbnails[0].id === activeThumbnail
                        ? "lrActiveThumbnail"
                        : ""
                    } three-sixty-image`}
                    onClick={() => setActiveThumbnail(productThumbnails[0].id)}
                  />
                </td>
              </tr>
              {thumbnailTable()}
            </tbody>
          </Table>
        </Col>
        <Col md={1}></Col>
      </Row>
      {/* Comparison Analysis */}
      <Row>
        <h2 className="comparisonAnalysisHeading">COMPARISON ANALYSIS</h2>
        {productThumbnails[activeThumbnail].comparisonAnalysis === null
          ? noPreview()
          : comparisonAnalysis()}
      </Row>
    </Container>
  );
}

export default LabReport;

import React, { useState, useEffect, useRef } from "react";
import "./ebook.css";
import "./node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Spinner from 'react-bootstrap/Spinner';
import EbookDiamondJourney from "./ebook-right-template/your-diamond-journey";
import EbookEarthMinedDiamonds from "./ebook-right-template/earth-mined-diamonds";
import EbookEthicalSourcing from "./ebook-right-template/ethical-sourcing";
import EbookCutAnalysis from "./ebook-right-template/cut-analysis";
import EbookDecagonWhiteSeries from "./ebook-right-template/decagon-white-series";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import EbookLabReport from "./ebook-right-template/lab-report";
import axios from "axios";
import EbookMobile from "./ebook-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faYoutube,
  faInstagram,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons";
import { faSearch, faVideo } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";

function Ebook() {
  const [productData, getProductData] = useState({});
  const [certificateNumber, setCertificateNumber] = useState("");
  const [selectedMenu, setSelectedMenu] = useState({menu : "EbookLabReport"});
  const [selectedTemplate, setSelectedTemplate] = useState(
    <EbookLabReport
    certificateNumber={certificateNumber}
    setCertificateNumber={setCertificateNumber}
    productData={productData}
    getProductData={getProductData} />
    );
  const apiURL =
  "https://staging.jannpaul.com/wp-content/plugins/les-product-management/public/factory-jannpaul-sync-handler.php?reactHandler=getEbookDataForReact";
  const [certificateNumberAvailable, setCertificateNumberAvailable] =
  useState(false);
  let isValidCertificateNumber = false;
  const [showAlert,setShowAlert] = useState(false);
  // width of window
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(false);
  const handleMenuClick = (currentlySelectedMenu) => {
    setSelectedMenu({menu: currentlySelectedMenu});
  };

  const validateCertificateNumber = (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetchData(certificateNumber);
    //validation code from fetchData to be added here
  };

  const displayEnteredCertificateNumber = (e) => {
    e.preventDefault();
    setCertificateNumber(e.target.value);
  };

  const fetchData = async (certificateNumber) => { 
    const responseProductData = await axios.get(
      apiURL +
      "&certificateNumber=" +
      certificateNumber 
      );
    if(responseProductData.data === "Not Available"){
      isValidCertificateNumber = false;
    }else{
      isValidCertificateNumber = true; 
    }
    // to be shifted inside validateCertificateNumber
    if(isValidCertificateNumber && certificateNumber!=="")
    {
      setCertificateNumberAvailable(true);
      const newMenu = {...selectedMenu}; 
      setSelectedMenu(newMenu);
    }else{
      setCertificateNumberAvailable(false);
      setShowAlert(true);
    }
    setIsLoading(false);
    getProductData(responseProductData.data);
    // console.log(responseProductData.data)
  };

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResizeWindow);
    switch(selectedMenu.menu){
    case "EbookEthicalSourcing": 
      return setSelectedTemplate(<EbookEthicalSourcing />);
    case "EbookDecagonWhiteSeries": 
      return setSelectedTemplate(<EbookDecagonWhiteSeries />);
    case "EbookDiamondJourney": 
      return setSelectedTemplate(<EbookDiamondJourney
        certificateNumber={certificateNumber}
        setCertificateNumber={setCertificateNumber}
        productData={productData}
        getProductData={getProductData}
        />);
    case "EbookEarthMinedDiamonds": 
      return setSelectedTemplate(<EbookEarthMinedDiamonds />);
    case "EbookCutAnalysis": 
      return setSelectedTemplate( <EbookCutAnalysis
        certificateNumber={certificateNumber}
        setCertificateNumber={setCertificateNumber}
        productData={productData}
        getProductData={getProductData}
        />);
    default: 
      return setSelectedTemplate(<EbookLabReport
        certificateNumber={certificateNumber}
        setCertificateNumber={setCertificateNumber}
        productData={productData}
        getProductData={getProductData}
        />);
    }
    
  }, [selectedMenu]);

  function EbookSkeleton() {
    return !certificateNumberAvailable ? (
      <>
      <ClientWebsiteHeader />
      <EbookLandingForm />
      <ClientWebsiteFooter />
      </>
      ) : width >= 992 ? (
        <>
        <EbookHeader />
        <div className="row">
        <EbookSidebar />
        <EbookRightTemplate />
        </div>
        </>
        ) : (<EbookMobile setShowAlert={setShowAlert} productData={productData} certificateNumber={certificateNumber} validateCertificateNumber={validateCertificateNumber} setCertificateNumberAvailable={setCertificateNumberAvailable}/>);
    }

    function EbookLandingForm() {
      return (
        <Row className="EbookLandingPageRow">
        <Col md={3}></Col>
        <Col md={6} className="searchReportBox">
        <h3>Enter your certificate number</h3>
        <p>Input the GCAL certificate number to view its details.</p>
        <Row className="reportNumberSearchBoxCTARow">
        <Col md={2}></Col>
        <Col md={4}> 
        <input
        type="text"
        autoFocus="autoFocus"
        className="reportNumberTextBox"
        name="certificateNumber"
        onChange={displayEnteredCertificateNumber}
        required
        value={certificateNumber} 
        placeholder="SEARCH"
        // onKeyDown={(e)=>e.key === 'Enter' ? validateCertificateNumber(e): ''}
        onKeyDown={certificateNumber.length === 9 ? (e) => e.key === 'Enter' ? validateCertificateNumber(e): "" : ()=>{}}
        />
        </Col>
        <Col md={4}>
        <button
        className="viewLabReport"
        type="submit"
        onClick={validateCertificateNumber}
        >
        {isLoading ? "LOADING..." : "VIEW LAB REPORT"}
        </button> 
        </Col>
        <Col md={2}></Col>
        </Row>
        <Row className="alertBoxRow">
        <Col md={2}></Col>
        <Col className="alertBoxColumn" md={8}>
        {showAlert ? (<Alert key="danger" variant="danger">
          Please enter valid Certificate Number
        </Alert>):(<p></p>)}
        </Col>
        <Col md={2}></Col>
        </Row>      
        </Col>
        <Col md={3}></Col>
        </Row>
        );
    }

    function ClientWebsiteHeader() {
      return (
        <Row className="dmolLandingHeader">
        <Col md={4} className="contactDetailsEbookLandingHeader">
        <p>
        sales@jannpaul.com <span className="phoneNumber">+65-90219891</span>
        </p>
        </Col>
        <Col md={4} className="jpLogoEbookLandingHeader">
        <Image
        className="logo"
        src="https://staging.jannpaul.com/wp-content/uploads/2023/01/Jannpaul-text-logo_big-300x45.png"
        />
        </Col>
        <Col md={4} className="loginIconsEbookLandingHeader">
        <Image
        src="https://staging.jannpaul.com/wp-content/uploads/2024/01/icon_login.png"
        className="loginImageEbookLandingHeader"
        />{" "}
        <p>LOGIN</p>
        <Image
        src="https://staging.jannpaul.com/wp-content/uploads/2022/04/heart.png"
        className="wishlistImageEbookLandingHeader"
        />{" "}
        <p>WISHLIST</p>
        <Image
        src="https://staging.jannpaul.com/wp-content/uploads/2024/02/JP_cart.png"
        className="cartImageEbookLandingHeader"
        />{" "}
        <p>CART</p>
        <Image
        src="https://staging.jannpaul.com/wp-content/uploads/2024/01/singapore-flag.png"
        className="flagImageEbookLandingHeader"
        />
        <Form.Select aria-label="flagDD" className="sgdDD">
        <option value="sgd">SGD</option>
        <option value="aug">AUG</option>
        </Form.Select>
        <Form className="searchBoxMainHeaderForm">
        <Form.Control
        plaintext
        className="searchBoxMainHeader"
        controlid=""
        placeholder="search"
        />
        <FontAwesomeIcon
        icon={faSearch}
        className="searcIconJPMainHeader"
        />
        </Form>
        </Col>
        <Row className="logoIconRowEbookLandingHeader">
        <Col md={5} className="leftBorder">
        <p className="headerBorder"></p>
        </Col>
        <Col md={2} className="jpLogoIconColumn">
        <Image
        className="jpLogoIcon"
        src="https://staging.jannpaul.com/wp-content/uploads/2022/04/MicrosoftTeams-image-49.png"
        />
        </Col>
        <Col md={5} className="rightBorder">
        <p className="headerBorder"></p>
        </Col>
        </Row>
        <Row className="jpMainMenuRow">
        <Nav
        className="jpMainMenu"
        activekey="#"
        // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
        >
        <Nav.Item>
        <Nav.Link href="#"></Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Nav.Link eventKey="link-1">JP SUPER IDEAL CUT</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Nav.Link eventKey="link-2">DIAMOND SEARCH</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Nav.Link eventKey="link-3">CUSTOMIZE YOUR RING</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Nav.Link eventKey="link-4">EDUCATION</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Nav.Link eventKey="link-5">CONATCT US</Nav.Link>
        </Nav.Item>
        <Nav.Item>
        <Nav.Link eventKey="link-6">
        LIVE VIDEO CONSULTATION{" "}
        <FontAwesomeIcon className="faVideoMainHeader" icon={faVideo} />{" "}
        </Nav.Link>
        </Nav.Item>
        </Nav>
        </Row>
        </Row>
        );
    }

    function ClientWebsiteFooter() {
      return (
        <Row className="jpMainFooter">
        <Row className="logoIconRowEbookLandingHeader">
        <Col md={5} className="leftBorder">
        <p className="headerBorder"></p>
        </Col>
        <Col md={2} className="jpLogoIconColumn">
        <Image
        className="jpLogoIcon"
        src="https://staging.jannpaul.com/wp-content/uploads/2022/04/MicrosoftTeams-image-49.png"
        />
        </Col>
        <Col md={5} className="rightBorder">
        <p className="headerBorder"></p>
        </Col>
        </Row>
        <Col md={2}></Col>
        {/* Adding this class to inactive links in footer */}
        <Col md={2} className="jpFooterLinks inactiveLinks">
        <h4>JANNPAUL EXPERIENCE</h4>
        <p>Live Video Consultation</p>
        <p>Lifetime Warranty</p>
        <p>Lifetime Upgrade</p>
        <p>Packaging</p>
        <p>Contact Us</p>
        </Col>
        <Col md={2} className="jpFooterLinks inactiveLinks">
        <h4>ABOUT US</h4>
        <p>About us</p>
        <p>Our Blog</p>
        <p>Press</p>
        <p>Facebook</p>
        <p>Instagram</p>
        <p>Youtube</p>
        </Col>
        <Col md={2} className="jpFooterLinks inactiveLinks">
        <h4>WHY US</h4>
        <p>Free Worldwide Shipping</p>
        <p>Insurance Coverage</p>
        <p>Free Resizing</p>
        <p>Free Engraving</p>
        <p>Conflict Free</p>
        <p>FAQ</p>
        </Col>
        <Col md={2} className="jpFooterLinks inactiveLinks">
        <h4>OUR POLICIES</h4>
        <p>Terms & Conditions</p>
        <p>Privacy Policy</p>
        <p>Cookies Policy</p>
        <p>Tax & Customs Duty</p>
        <p>Shipping & Return</p>
        </Col>
        <Col md={2}></Col>
        </Row>
        );
    }

    function EbookHeader() {
      return (
        <Row className="dmolHeader">
        <Col md={4} className="contactDetails">
        <p>
        sales@jannpaul.com <span className="phoneNumber">+65-90219891</span>
        </p>
        </Col>
        <Col md={4} className="jpLogo">
        <Image
        className="logo"
        src="https://staging.jannpaul.com/wp-content/uploads/2023/01/Jannpaul-text-logo_big-300x45.png"
        />
        </Col>
        <Col md={4} className="socialsAndReportNumber">
        <FontAwesomeIcon icon={faFacebookF} />
        <FontAwesomeIcon icon={faYoutube} />
        <FontAwesomeIcon icon={faInstagram} />
        <FontAwesomeIcon icon={faPinterestP} />
        </Col>
        <Row className="reportNumberRow">
        <Col>
        <input
        className="reportNumberBox"
        autoFocus="autoFocus"
        type="text"
        placeholder="Enter Report Number"
        value={certificateNumber}
        onChange={displayEnteredCertificateNumber}
        required
        onKeyDown={(e)=>e.key === 'Enter' ? validateCertificateNumber(e): ''}
        />
        <Button
        className="verifyReportNumberCta"
        onClick={validateCertificateNumber}
        >
        {isLoading ? "LOADING..." : "VIEW LAB REPORT"}
        </Button>
        </Col>
        </Row>
        <Row className="logoIconRow">
        <Col
        md={5}
        className="leftBorder"
        >
        <p className="headerBorder"></p>
        </Col>
        <Col md={2} className="jpLogoIconColumn">
        <Image
        className="jpLogoIcon"
        src="https://staging.jannpaul.com/wp-content/uploads/2022/04/MicrosoftTeams-image-49.png"
        />
        </Col>
        <Col md={5} className="rightBorder">
        <p className="headerBorder"></p>
        </Col>
        </Row>
        </Row>
        );
    }

    function EbookSidebar() {
    //below sidebar code to be moved to ebook-left-template directory for easy searchability
      if (Object.keys(productData).length > 0) {
        return (
          <Col md={4} className="sideBar">
          <p className="dmolSidebarTitle">Diamond Report Summary</p>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Report Number</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.certificateNo}</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Report Date</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.reportDate}</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Description</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.description}</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Shape and Cut diamonds</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.shape}</p>
          </Col>
          </Row>
          <p className="dmolSidebarTitle">Grading Results</p>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Carat Weight</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.carat} Carat</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Color Grade</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.color}</p> 
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Clarity Grade</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.clarity}</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Cut Grade</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.cut}</p>
          </Col>
          </Row>
          <p className="dmolSidebarTitle">Additional Grading Information</p>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Polish</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.polish}</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Symmetry</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.symmetry}</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Measurements</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.measurements}mm</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Table Size</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.table}%</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Crown Height</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.crownHeight}%</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Pavilion Depth</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.pavillionHeight}%</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Gridle Thickness</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.girdle}</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Culet</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.culet}</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Total Depth</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.depth}%</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Fluorescence</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.fluorescence}</p>
          </Col>
          </Row>
          <Row>
          <Col md={6} className="leftValueDmolSidebar">
          <p>Comments</p>
          </Col>
          <Col md={6} className="rightValueDmolSidebar">
          <p>{productData.sidebarData.comments !== "" ? productData.sidebarData.comments : "No comments"}</p>
          </Col>
          </Row>
          </Col>
          );
}
}

function EbookMenu() {
 
  return (
    <Row className="eBookMenu">
    <Col>
    <Nav onSelect={handleMenuClick}>
    <Nav.Item>
    <Nav.Link className={selectedMenu.menu === "EbookLabReport" ? 'active' : ''} eventKey="EbookLabReport">LAB REPORT</Nav.Link>
    </Nav.Item>
    <Nav.Item>
    <Nav.Link className={selectedMenu.menu === "EbookDiamondJourney" ? 'active' : ''} eventKey="EbookDiamondJourney">
    YOUR DIAMOND JOURNEY
    </Nav.Link>
    </Nav.Item>
    <Nav.Item>
    <Nav.Link className={selectedMenu.menu === "EbookCutAnalysis" ? 'active' : ''} eventKey="EbookCutAnalysis">CUT ANALYSIS</Nav.Link>
    </Nav.Item>
    <Nav.Item>
    <Nav.Link className={selectedMenu.menu === "EbookDecagonWhiteSeries" ? 'active' : ''} eventKey="EbookDecagonWhiteSeries">
    WHAT IS DECAGON WHITE SERIES<sup>TM</sup>?
    </Nav.Link>
    </Nav.Item>
    <Nav.Item>
    <Nav.Link className={selectedMenu.menu === "EbookEarthMinedDiamonds" ? 'active' : ''} eventKey="EbookEarthMinedDiamonds">
    ABOUT EARTH MINED DIAMONDS
    </Nav.Link>
    </Nav.Item>
    <Nav.Item>
    <Nav.Link className={selectedMenu.menu === "EbookEthicalSourcing" ? 'active' : ''} eventKey="EbookEthicalSourcing">
    ETHICAL SOURCING
    </Nav.Link>
    </Nav.Item>
    </Nav> 
    </Col>
    </Row>
    );
}

function EbookRightTemplate() {
  return (
    <>
    <Col md={8} className="dmolContent">
    <EbookMenu />
    {selectedTemplate}
    </Col>
    </>
    );
}

return <EbookSkeleton />;
}
export default Ebook;

import { useState } from 'react';
import parse from 'html-react-parser';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
const EbookMobileLabReport = ({ productData, certificateNumber }) => {
  const [index, setIndex] = useState(0);
  const handleSelect = selectedIndex => {
    setIndex(selectedIndex);
  };
  const productThumbnails = productData.labReportData;
  const MobileLabReportComparisonAnalysis = () => {
    const currentDisplay = productThumbnails[index].comparisonAnalysis;
    return (
      <>
          {productThumbnails[index].extension != 'html' ? (
            //Images
            <>
              <Col xs={3}>
                <Image src={currentDisplay.img1} className="comparisonAnalysisImages" />
              </Col>
              <Col xs={3}>
                <Image src={currentDisplay.img2} className="comparisonAnalysisImages" />
              </Col>
              <Col xs={3}>
                <Image src={currentDisplay.img3} className="comparisonAnalysisImages" />
              </Col>
              <Col xs={3}>
                <Image src={currentDisplay.img4} className="comparisonAnalysisImages" />
              </Col>
            </>
          ) : (
            //videos
            <>
              <Col xs={3}>
                <video muted="" autoplay="" loop="" className="comparisonAnalysisVideo">
                  <source src={currentDisplay.video1} type="video/mp4" />
                </video>
              </Col>
              <Col xs={3}>
                <video muted="" autoplay="" loop="" className="comparisonAnalysisVideo">
                  <source src={currentDisplay.video2} type="video/mp4" />
                </video>
              </Col>
              <Col xs={3}>
                <video muted="" autoplay="" loop="" className="comparisonAnalysisVideo">
                  <source src={currentDisplay.video3} type="video/mp4" />
                </video>
              </Col>
              <Col xs={3}>
                <video muted="" autoplay="" loop="" className="comparisonAnalysisVideo">
                  <source src={currentDisplay.video4} type="video/mp4" />
                </video>
              </Col>
            </>
          )}
          <Col>
          {/* Parsing string to JSX */}
          {parse(currentDisplay.writeup)}
        </Col>
      </>
    );
  };
  const noPreview = () => {
    return (
      <Row>
        <Col xs={3}></Col>
        <Col xs={6}>
          <Alert variant="warning">No Preview Available</Alert>
        </Col>
        <Col xs={3}></Col>
      </Row>
    );
  };
  return (
    <>
      {/* Menu Title */}
      <Row className="ebookSectionTitle">
        <h4 className="titleEbook">Lab Report</h4>
      </Row>
      {/* gcal certificate */}
      <Row>
        {/* <iframe src={productData.labReportCertificate}></iframe> */}
        <embed className="eBookCertificateMobile" src={productData.labReportCertificate}></embed>
      </Row>
      {/* Thumbnail Slider */}
      <Row>
        <Carousel activeIndex={index} onSelect={handleSelect} controls={false} interval={3000}>
          {productThumbnails.map((thumbnail, index) => (
            <Carousel.Item key={index}>
              <p className="carouselItemTitle">{thumbnail.name}</p>
              {thumbnail.iframe === null ? (
                <Image src={thumbnail.image} />
              ) : (
                <iframe src={thumbnail.iframe}></iframe>
              )}
              <p className="carouselItemReportNumber">{certificateNumber}</p>
            </Carousel.Item>
          ))}
        </Carousel>
      </Row>
      {/* Comparison Analysis */}
      <Row>
        <h4>COMPARISON ANALYSIS</h4>
        {/* No preview or content */}
        {productThumbnails[index].comparisonAnalysis === null
          ? noPreview()
          : MobileLabReportComparisonAnalysis()}
      </Row>
    </>
  );
};
export default EbookMobileLabReport;

import React, { useState, useEffect } from 'react';
import '../ebook.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import axios from 'axios';
import DownloadAllModal from './download-all-modal';


function DiamondJourney({certificateNumber, setCertificateNumber, productData, getProductData}){
  // Added - start
  const [show, setShow] = useState(false);

  const handleShow =() => setShow(true);
  const handleClose = () => setShow(false);

  //end

  if(Object.keys(productData).length > 0)
  {
    var stageCounter = 0;
    var mediaCounter = 0;
    return(
      <Row className="diamondJourneyRow">
      <Col md={12}>
      <Tab.Container id="left-tabs-example" defaultActiveKey="djTab1">
      <Row>
      <Col md={5}>
      <Nav variant="pills" className="flex-column diamondJourneyTabSection">
      {productData.diamondJourneyData && productData.diamondJourneyData.map((diamondJourneySingularData) => {
        stageCounter = stageCounter + 1;
        var eventKeyCounter = "djTab" + stageCounter;
        return (
          <Nav.Item className="dJTabs" key={eventKeyCounter}>
          <Nav.Link eventKey={eventKeyCounter} className="djTabLink">
          <h3>{diamondJourneySingularData.diamondJourneyContentHeading}</h3>
          <p>{diamondJourneySingularData.diamondJourneyContentSubtitle}</p>
          </Nav.Link>
          </Nav.Item>
          );
      })}
      </Nav>
      </Col>
      <Col md={7}>
      <Tab.Content>
      {productData.diamondJourneyData && productData.diamondJourneyData.map((diamondJourneySingularData) => {
        mediaCounter = mediaCounter + 1;
        var eventKeyCounter = "djTab" + mediaCounter;
        return (
          <Tab.Pane eventKey={eventKeyCounter} key={eventKeyCounter}>
          <Row className="djTabContent">
          <Col md={12} className="diamondJourneyMediaContent">
          <Image src={diamondJourneySingularData.diamondJourneyContentMediaUrl} className="djTabImage diamondJourneyMediaContent"/>
          </Col>
          <Col md={8} className="djTabCertificateNumber">
          <p>{diamondJourneySingularData.diamondJourneyContentSubtitle}</p>
          <p>Certificate No. {certificateNumber}</p>
          </Col>
          <Col md={4} className="djTabDownloadCTA">
          {/* Updated - start */}
          <Button href="#" onClick={handleShow}>DOWNLOAD ALL</Button>
          {/* end */}
          </Col>
          <p>{diamondJourneySingularData.diamondJourneyContentDescription}</p>
          </Row>              
          </Tab.Pane>
          );
      })}
      </Tab.Content>
       {/* Modal will be shown according to button click */}
       <DownloadAllModal show={show} handleClose={handleClose} certificateNumber={certificateNumber}/>
      </Col>
      </Row>
      </Tab.Container>
      </Col>
      </Row>
      );
  }
}

export default DiamondJourney;